var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "router-link",
          { staticClass: "left", attrs: { to: { name: "CreatorDashboard" } } },
          [
            _c("img", { attrs: { src: require("@/assets/img/logo.svg") } }),
            _c("span", [_vm._v("サイト管理")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "right", on: { click: _vm.switchMenuDisplay } },
          [
            _c("span", [_vm._v(_vm._s(_vm.model.channelName))]),
            _c("fa-icon", { attrs: { icon: "bars" } }),
          ],
          1
        ),
        _vm.model.displayMenu
          ? _c("div", { staticClass: "menu" }, [
              _c("ul", [
                _c("li", { on: { click: _vm.signOut } }, [
                  _c(
                    "div",
                    [_c("fa-icon", { attrs: { icon: "sign-out-alt" } })],
                    1
                  ),
                  _c("span", [_vm._v("この端末からログアウト")]),
                ]),
                _c("li", { on: { click: _vm.signOutAll } }, [
                  _c(
                    "div",
                    [_c("fa-icon", { attrs: { icon: "sign-out-alt" } })],
                    1
                  ),
                  _c("span", [_vm._v("すべての端末からログアウト")]),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm.model.displayMenu
      ? _c("div", {
          staticClass: "layerMenu",
          on: { click: _vm.switchMenuDisplay },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }