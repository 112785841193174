import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/signin",
    name: "CreatorSignIn",
    component: () => import("../views/CreatorSignIn/CreatorSignIn.vue"),
    meta: {
      navigationType: "default",
      title: "サインイン - RemDia",
      description: "",
    },
  },
  {
    path: "/",
    name: "CreatorDashboard",
    component: () => import("../views/CreatorDashboard/CreatorDashboard.vue"),
    meta: {
      navigationType: "creator",
      title: "ダッシュボード - RemDia",
      description: "",
    },
  },
  {
    path: "/subscription",
    name: "CreatorPlans",
    component: () => import("../views/CreatorPlans/CreatorPlans.vue"),
    meta: {
      navigationType: "creator",
      title: "サブスクリプション - RemDia",
      description: "",
    },
  },
  {
    path: "/subscription/create",
    name: "CreatorPlanCreate",
    component: () => import("../views/CreatorPlanCreate/CreatorPlanCreate.vue"),
    meta: {
      navigationType: "creator",
      title: "サブスクリプションの作成 - RemDia",
      description: "",
    },
  },
  {
    path: "/subscription/edit/:planCode",
    name: "CreatorPlanEdit",
    component: () => import("../views/CreatorPlanEdit/CreatorPlanEdit.vue"),
    meta: {
      navigationType: "creator",
      title: "サブスクリプションの編集 - RemDia",
      description: "",
    },
  },
  {
    path: "/group",
    name: "CreatorContentGroups",
    component: () =>
      import("../views/CreatorContentGroups/CreatorContentGroups.vue"),
    meta: {
      navigationType: "creator",
      title: "グループ - RemDia",
      description: "",
    },
  },
  {
    path: "/group/create",
    name: "CreatorContentGroupCreate",
    component: () =>
      import("../views/CreatorContentGroupCreate/CreatorContentGroupCreate.vue"),
    meta: {
      navigationType: "creator",
      title: "グループの作成 - RemDia",
      description: "",
    },
  },
  {
    path: "/group/edit/:contentGroupCode",
    name: "CreatorContentGroupEdit",
    component: () =>
      import("../views/CreatorContentGroupEdit/CreatorContentGroupEdit.vue"),
    meta: {
      navigationType: "creator",
      title: "グループの編集 - RemDia",
      description: "",
    },
  },
  {
    path: "/site",
    name: "CreatorChannel",
    component: () => import("../views/CreatorChannel/CreatorChannel.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト設定 - RemDia",
      description: "",
    },
  },
  {
    path: "/site/default",
    name: "CreatorChannelDefault",
    component: () => import("../views/CreatorChannelDefault/CreatorChannelDefault.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト > 基本情報の編集 - RemDia",
      description: "",
    },
  },
  {
    path: "/site/home",
    name: "CreatorChannelHome",
    component: () => import("../views/CreatorChannelHome/CreatorChannelHome.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト > ホーム画面の編集 - RemDia",
      description: "",
    },
  },
  {
    path: "/site/default",
    name: "CreatorChannelDefault",
    component: () => import("../views/CreatorChannelDefault/CreatorChannelDefault.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト設定 > 基本設定 - RemDia",
      description: "",
    },
  },
  {
    path: "/site/page",
    name: "CreatorChannelPages",
    component: () => import("../views/CreatorChannelPages/CreatorChannelPages.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト設定 > フリーページ - RemDia",
      description: "",
    },
  },
  {
    path: "/site/page/edit/:freePageCode",
    name: "CreatorChannelPageEdit",
    component: () => import("../views/CreatorChannelPageEdit/CreatorChannelPageEdit.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト設定 > フリーページの編集 - RemDia",
      description: "",
    },
  },
  {
    path: "/site/page/create",
    name: "CreatorChannelPageCreate",
    component: () => import("../views/CreatorChannelPageCreate/CreatorChannelPageCreate.vue"),
    meta: {
      navigationType: "creator",
      title: "サイト設定 > フリーページの作成 - RemDia",
      description: "",
    },
  },
  {
    path: "/video",
    name: "CreatorContents",
    component: () => import("../views/CreatorContents/CreatorContents.vue"),
    meta: {
      navigationType: "creator",
      title: "動画 - RemDia",
      description: "",
    },
  },
  {
    path: "/video/edit/:contentCode",
    name: "CreatorContentEdit",
    component: () => import("../views/CreatorContentEdit/CreatorContentEdit.vue"),
    meta: {
      navigationType: "creator",
      title: "動画の編集 - RemDia",
      description: "",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
