export class Model {
  public step: string = "1";
  public step1ButtonActive: boolean = true;
  public step1ButtonLoading: boolean = false;
  public step1Errors: Array<Error> = new Array<Error>();
  public code: InputCcreditCardNumber = new InputCcreditCardNumber();
}
export class InputCcreditCardNumber {
  public clear() {
    this.value1 = "";
    this.value2 = "";
    this.value3 = "";
    this.value4 = "";
  }
  get value(): string {
    return this.value1 + this.value2 + this.value3 + this.value4;
  }
  public value1: string = "";
  public value2: string = "";
  public value3: string = "";
  public value4: string = "";
  public focusNumber: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
