var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _c("div", { staticClass: "page-body" }, [
      _c("div", { staticClass: "form" }, [
        _vm.model.step !== "2"
          ? _c("div", { staticClass: "form-body" }, [
              _vm.model.step === "1"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "key" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.password" },
                          },
                          [_vm._v("現在のパスワード")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.password.value,
                              expression: "model.password.value",
                            },
                          ],
                          class: { error: _vm.model.password.isError },
                          attrs: { type: "password", id: "model.password" },
                          domProps: { value: _vm.model.password.value },
                          on: {
                            change: function ($event) {
                              return _vm.validationCheck("password")
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.password,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.validationCheck("password", true)
                              },
                            ],
                          },
                        }),
                        _vm.model.password.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "errors" },
                              _vm._l(
                                _vm.model.password.errors,
                                function (item1) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.model.password.errors.indexOf(
                                        item1
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("fa-icon", {
                                            attrs: {
                                              icon: "exclamation-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item1.message)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("hr"),
              _vm.model.step === "1"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "lock" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.newPassword.value1" },
                          },
                          [_vm._v("新しいパスワード")]
                        ),
                        _c("div", { staticClass: "twoColumn" }, [
                          _vm.model.newPassword.view == false
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.newPassword.value1,
                                    expression: "model.newPassword.value1",
                                  },
                                ],
                                class: { error: _vm.model.newPassword.isError },
                                attrs: {
                                  type: "password",
                                  id: "model.newPassword.value1",
                                },
                                domProps: {
                                  value: _vm.model.newPassword.value1,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validationCheck("newPassword")
                                  },
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model.newPassword,
                                        "value1",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validationCheck(
                                        "newPassword",
                                        true
                                      )
                                    },
                                  ],
                                },
                              })
                            : _vm._e(),
                          _vm.model.newPassword.view == true
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.newPassword.value1,
                                    expression: "model.newPassword.value1",
                                  },
                                ],
                                class: { error: _vm.model.newPassword.isError },
                                attrs: {
                                  type: "password",
                                  id: "model.newPassword.value1",
                                },
                                domProps: {
                                  value: _vm.model.newPassword.value1,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validationCheck("newPassword")
                                  },
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.model.newPassword,
                                        "value1",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validationCheck(
                                        "newPassword",
                                        true
                                      )
                                    },
                                  ],
                                },
                              })
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.newPassword.view,
                                expression: "model.newPassword.view",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "model.newPassword.view",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.model.newPassword.view)
                                ? _vm._i(_vm.model.newPassword.view, null) > -1
                                : _vm.model.newPassword.view,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.model.newPassword.view,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.model.newPassword,
                                        "view",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.model.newPassword,
                                        "view",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.model.newPassword, "view", $$c)
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "model.newPassword.view" } },
                            [_vm._v("パスワードを表示")]
                          ),
                        ]),
                        _vm.model.newPassword.view == false
                          ? _c(
                              "label",
                              {
                                staticClass: "title",
                                attrs: { for: "model.newPassword.value2" },
                              },
                              [_vm._v("新しいパスワード（確認）")]
                            )
                          : _vm._e(),
                        _vm.model.newPassword.view == false
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.newPassword.value2,
                                  expression: "model.newPassword.value2",
                                },
                              ],
                              class: { error: _vm.model.newPassword.isError },
                              attrs: {
                                type: "password",
                                id: "model.newPassword.value2",
                              },
                              domProps: { value: _vm.model.newPassword.value2 },
                              on: {
                                change: function ($event) {
                                  return _vm.validationCheck("newPassword")
                                },
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model.newPassword,
                                      "value2",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.validationCheck(
                                      "newPassword",
                                      true
                                    )
                                  },
                                ],
                              },
                            })
                          : _vm._e(),
                        _vm.model.newPassword.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "errors" },
                              _vm._l(
                                _vm.model.newPassword.errors,
                                function (item1) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.model.newPassword.errors.indexOf(
                                        item1
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("fa-icon", {
                                            attrs: {
                                              icon: "exclamation-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item1.message)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.model.step === "1"
          ? _c("div", { staticClass: "form-footer" }, [
              _vm.model.step1Errors.length > 0
                ? _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.step1Errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.step1Errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.validationCheck("all", true, true)
                ? _c("div", { staticClass: "info info-validation-error" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("fa-icon", {
                          attrs: { icon: "exclamation-circle" },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("不備のある項目が存在します")]),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  attrs: {
                    disabled:
                      !_vm.model.step1ButtonActive ||
                      !_vm.validationCheck("all", true, true),
                  },
                  on: { click: _vm.step1Process },
                },
                [
                  _vm.model.step1ButtonLoading
                    ? _c("div", { staticClass: "spinner bubble" })
                    : _vm._e(),
                  !_vm.model.step1ButtonLoading
                    ? _c("span", [_vm._v("パスワードの変更")])
                    : _vm._e(),
                ]
              ),
            ])
          : _vm._e(),
        _vm.model.step === "2"
          ? _c("div", { staticClass: "form-footer" }, [
              _c("div", { staticClass: "info info-completed" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("fa-icon", { attrs: { icon: "check" } })],
                  1
                ),
                _c("span", [_vm._v("パスワードの変更が完了しました")]),
              ]),
              _c("button", { on: { click: _vm.step2Process } }, [
                _c("span", [_vm._v("再ログインする")]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "code" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.step,
            expression: "model.step",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.model.step },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.model, "step", $event.target.value)
          },
        },
      }),
      _c("pre", [_vm._v(_vm._s(_vm.model))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("パスワードの変更")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }