// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import { commonModule } from "@/store/modules/Common";

@Component
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public async mounted() {
    await this.authCheck();
    commonModule.load();
    this.model.channelName = commonModule.channelName;
    this.loadHeader();
  }
  public async loadHeader() {
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/Header/Main`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.model.channelName = response.channelName;
        commonModule.setChannelName(response.channelName);
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();
  }
  public switchMenuDisplay() {
    this.model.displayMenu = !this.model.displayMenu;
  }
  public signOut() {
    this.creatorUserManager.signOut({
      success: () => {
        this.$router.push({ name: "CreatorSignIn", params: {} });
      },
      failure: (err: Error) => {
        throw err;
      },
    });
  }
  public signOutAll() {
    this.creatorUserManager.signOutAll({
      success: () => {
        this.$router.push({ name: "CreatorSignIn", params: {} });
      },
      failure: (err: Error) => {
        throw err;
      },
    });
  }
}
