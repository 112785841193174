var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _c("div", { staticClass: "page-body" }, [
      _c("div", { staticClass: "form" }, [
        _vm.model.step !== "3"
          ? _c(
              "div",
              {
                staticClass: "form-body",
                staticStyle: { "margin-bottom": "0px" },
              },
              [
                _vm.model.step === "1"
                  ? _c("div", { staticClass: "formGroup" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [_c("fa-icon", { attrs: { icon: "envelope" } })],
                        1
                      ),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "rap" }, [
                          _c(
                            "label",
                            {
                              staticClass: "title",
                              attrs: { for: "model.newMailAddress" },
                            },
                            [_vm._v("現在のメールアドレス")]
                          ),
                          _vm._v(" " + _vm._s(_vm.model.oldMailAddress) + " "),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "page-body" }, [
      _c("div", { staticClass: "form" }, [
        _vm.model.step !== "3"
          ? _c("div", { staticClass: "form-body" }, [
              _vm.model.step === "1"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "envelope" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.newMailAddress" },
                          },
                          [_vm._v("新しいメールアドレス")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.newMailAddress.value,
                              expression: "model.newMailAddress.value",
                            },
                          ],
                          staticClass: "mail-width",
                          class: { error: _vm.model.newMailAddress.isError },
                          attrs: { type: "text", id: "model.newMailAddress" },
                          domProps: { value: _vm.model.newMailAddress.value },
                          on: {
                            change: function ($event) {
                              return _vm.validationCheck("mailAddress")
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.newMailAddress,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.validationCheck("mailAddress", true)
                              },
                            ],
                          },
                        }),
                        _vm.model.newMailAddress.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "errors" },
                              _vm._l(
                                _vm.model.newMailAddress.errors,
                                function (item1) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.model.newMailAddress.errors.indexOf(
                                        item1
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("fa-icon", {
                                            attrs: {
                                              icon: "exclamation-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item1.message)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.step === "1" ? _c("hr") : _vm._e(),
              _vm.model.step === "1"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "key" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.password" },
                          },
                          [_vm._v("現在のパスワード")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.password.value,
                              expression: "model.password.value",
                            },
                          ],
                          class: { error: _vm.model.password.isError },
                          attrs: { type: "password", id: "model.password" },
                          domProps: { value: _vm.model.password.value },
                          on: {
                            change: function ($event) {
                              return _vm.validationCheck("password")
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.password,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.validationCheck("password", true)
                              },
                            ],
                          },
                        }),
                        _vm.model.password.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "errors" },
                              _vm._l(
                                _vm.model.password.errors,
                                function (item1) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.model.password.errors.indexOf(
                                        item1
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("fa-icon", {
                                            attrs: {
                                              icon: "exclamation-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item1.message)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.step === "2"
                ? _c("p", { staticClass: "message" }, [
                    _vm._v(" 「"),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.model.newMailAddress.value)),
                    ]),
                    _vm._v("」に確認コードを送信しました。"),
                    _c("br"),
                    _vm._v(
                      " メールに記入されている確認コードを入力してください。 "
                    ),
                  ])
                : _vm._e(),
              _vm.model.step === "2" ? _c("hr") : _vm._e(),
              _vm.model.step === "2"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "envelope" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.code" },
                          },
                          [_vm._v("メールアドレス")]
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.model.newMailAddress.value)),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.step === "2" ? _c("hr") : _vm._e(),
              _vm.model.step === "2"
                ? _c("div", { staticClass: "formGroup" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [_c("fa-icon", { attrs: { icon: "check" } })],
                      1
                    ),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "rap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "title",
                            attrs: { for: "model.code" },
                          },
                          [_vm._v("確認コード")]
                        ),
                        _c("div", { staticClass: "code-form" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value1,
                                expression: "model.code.value1",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue1", type: "text" },
                            domProps: { value: _vm.model.code.value1 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value1",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(1)
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value2,
                                expression: "model.code.value2",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue2", type: "text" },
                            domProps: { value: _vm.model.code.value2 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value2",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(2)
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value3,
                                expression: "model.code.value3",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue3", type: "text" },
                            domProps: { value: _vm.model.code.value3 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value3",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(3)
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value4,
                                expression: "model.code.value4",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue4", type: "text" },
                            domProps: { value: _vm.model.code.value4 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value4",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(4)
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value5,
                                expression: "model.code.value5",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue5", type: "text" },
                            domProps: { value: _vm.model.code.value5 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value5",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(5)
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.code.value6,
                                expression: "model.code.value6",
                              },
                            ],
                            class: { error: _vm.model.code.isError },
                            attrs: { id: "codeValue6", type: "text" },
                            domProps: { value: _vm.model.code.value6 },
                            on: {
                              change: function ($event) {
                                return _vm.validationCheck("code")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model.code,
                                    "value6",
                                    $event.target.value
                                  )
                                },
                                _vm.codeChange,
                              ],
                              keydown: _vm.codeOnKey,
                              keyup: _vm.codeOnKey,
                              focus: function ($event) {
                                return _vm.codeFocus(6)
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "back-step1" }, [
                          _c("span", { on: { click: _vm.backStep1 } }, [
                            _vm._v("確認コードを再送信"),
                          ]),
                        ]),
                        _vm.model.code.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "errors" },
                              _vm._l(_vm.model.code.errors, function (item1) {
                                return _c(
                                  "div",
                                  { key: _vm.model.code.errors.indexOf(item1) },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "exclamation-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", [_vm._v(_vm._s(item1.message))]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.model.step === "1"
          ? _c("div", { staticClass: "form-footer" }, [
              _vm.model.step1Errors.length > 0
                ? _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.step1Errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.step1Errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.validationCheck("all", true, true)
                ? _c("div", { staticClass: "info info-validation-error" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("fa-icon", {
                          attrs: { icon: "exclamation-circle" },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("不備のある項目が存在します")]),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  attrs: {
                    disabled:
                      !_vm.model.step1ButtonActive ||
                      !_vm.validationCheck("all", true, true),
                  },
                  on: { click: _vm.step1Process },
                },
                [
                  _vm.model.step1ButtonLoading
                    ? _c("div", { staticClass: "spinner bubble" })
                    : _vm._e(),
                  !_vm.model.step1ButtonLoading
                    ? _c("span", [_vm._v("確認コードを送信")])
                    : _vm._e(),
                ]
              ),
            ])
          : _vm._e(),
        _vm.model.step === "2"
          ? _c("div", { staticClass: "form-footer" }, [
              _vm.model.step2Errors.length > 0
                ? _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.step2Errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.step2Errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.validationCheck("all", true, true)
                ? _c("div", { staticClass: "info info-validation-error" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("fa-icon", {
                          attrs: { icon: "exclamation-circle" },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("不備のある項目が存在します")]),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  attrs: {
                    disabled:
                      !_vm.model.step2ButtonActive ||
                      !_vm.validationCheck("all", true, true),
                  },
                  on: { click: _vm.step2Process },
                },
                [
                  _vm.model.step2ButtonLoading
                    ? _c("div", { staticClass: "spinner bubble" })
                    : _vm._e(),
                  !_vm.model.step2ButtonLoading
                    ? _c("span", [_vm._v("メールアドレスの変更")])
                    : _vm._e(),
                ]
              ),
            ])
          : _vm._e(),
        _vm.model.step === "3"
          ? _c("div", { staticClass: "form-footer" }, [
              _c("div", { staticClass: "info info-completed" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("fa-icon", { attrs: { icon: "check" } })],
                  1
                ),
                _c("span", [_vm._v("メールアドレスの変更が完了しました")]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "code" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.step,
            expression: "model.step",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.model.step },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.model, "step", $event.target.value)
          },
        },
      }),
      _c("pre", [_vm._v(_vm._s(_vm.model))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("メールアドレスの変更")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }