// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import settingMailAddress from "@/components/SettingMailAddress/SettingMailAddress.vue";
import settingPassword from "@/components/SettingPassword/SettingPassword.vue";
import settingInvoices from "@/components/SettingInvoices/SettingInvoices.vue";
import settingPayment from "@/components/SettingPayment/SettingPayment.vue";

@Component({
  components: {
    settingMailAddress,
    settingPassword,
    settingInvoices,
    settingPayment,
  },
})
export default class extends BaseVue {
  public model = new model.Model();
  public created() {
    //
  }
  public async mounted() {
    await this.authCheck();
  }
  public jumpPage(select: string): void {
    this.model.select = select;
  }
  public settingModalClose(): void {
    this.$modal.hide("setting-modal");
  }
}
