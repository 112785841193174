// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";

@Component
export default class extends BaseVue {
  public model = new model.Model();
  public created() {
    //
  }
  public async mounted() {
    //
    this.model.step = "1";
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    let result = true;
    let input: any;
    let errors: any;
    result = true;
    if (this.model.step === "1") {
      //
    }
    return result;
  }
  public async step1Process(): Promise<void> {
    this.model.code.errors.push(new model.Error("不明なエラーが発生しました"));
    this.model.code.errors.push(new model.Error("不明なエラーが発生しました"));
  }
}
