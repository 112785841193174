var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: require("@/assets/img/logo.svg"), alt: "RemDia" },
    }),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "left" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: { name: "CreatorSignIn" } } }, [
                _vm._v("利用規約"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContents" } } },
                [_vm._v("特定商取引法に基づく表記")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContentGroups" } } },
                [_vm._v("個人情報保護方針")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContentGroups" } } },
                [_vm._v("QA")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContentGroups" } } },
                [_vm._v("ご利用ガイド")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContentGroups" } } },
                [_vm._v("お問い合わせ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("span", [_vm._v("©2020 Amano System Lab. All Rights Reserved")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }