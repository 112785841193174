import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import CreatorUserManager from "@/plugin/CreatorUserManager";
import VModal from "vue-js-modal";
Vue.use(VModal);

export default class extends Vue {
  public creatorUserManager = new CreatorUserManager();
  public setMeta(routeInstance: any): void {
    if (routeInstance.meta.title) {
      const title = routeInstance.meta.title;
      document.title = title;
    }
    if (routeInstance.meta.description) {
      const description = routeInstance.meta.description;
      (document as any)
        .querySelector("meta[name='description']")
        .setAttribute("content", description);
    }
  }
  public loadingCount: number = 0;
  public get isLoading(): boolean {
    return this.loadingCount > 0;
  }
  public async loadingOn() {
    this.loadingCount++;
    await this.sleep(2000);
    if (this.loadingCount > 0) {
      this.$modal.show("loading-modal");
    } else {
      this.$modal.hide("loading-modal");
    }
  }
  public async loadingOff() {
    this.loadingCount--;
    if (this.loadingCount == 0) {
      this.$modal.hide("loading-modal");
    }
  }
  public sleep(msec: number): Promise<void> {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, msec);
    });
  }
  public async authCheck() {
    await this.creatorUserManager
      .sinInCheck()
      .then(() => {
        if (this.$route.name === "CreatorSignIn") {
          this.$router.push({
            name: "CreatorDashboard",
            params: {},
          });
        }
      })
      .catch((ex) => {
        throw ex;
        this.$router.push({
          name: "CreatorSignIn",
          params: {},
        });
        throw ex;
      });
  }
  public hankaku2Zenkaku(text: string): string {
    return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  }
  public youtubeWatch(text: string): string {
    const match = text.match(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
    );
    if (match == null) {
      return text;
    }
    if (match[5] == null) {
      return text;
    }
    return match[5];
  }
  public base64toUint8Array(base64: string): Uint8Array {
    const binary = atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes;
  }
}
