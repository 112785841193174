// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultNavigation from "@/components/DefaultNavigation/DefaultNavigation.vue";
import CreatorNavigation from "@/components/CreatorNavigation/CreatorNavigation.vue";

@Component({
  components: {
    DefaultNavigation,
    CreatorNavigation,
  },
})
export default class extends Vue {
  public navigationType: string = "";
  public created() {
    this.setNavigationType();
  }
  @Watch("$route")
  public setNavigationType() {
    if (this.$route.meta != undefined) {
      this.navigationType = this.$route.meta.navigationType;
    }
  }
}
