import {
  Mutation,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store";

// state's interface
export interface ICommonState {
  channelName: string;
  mailAddress: string;
}
@Module({ dynamic: true, store, name: "common", namespaced: true })
class Common extends VuexModule implements ICommonState {
  public channelName: string = "";
  public mailAddress: string = "";
  @Mutation
  public setChannelName(value: string) {
    this.channelName = value;
  }
  @Mutation
  public setMailAddress(value: string) {
    this.mailAddress = value;
  }
  @Action({rawError: true})
  public load() {
    const json = localStorage.getItem("store.common");
    if (json == null) {
      return;
    }
    const jsonObject = JSON.parse(json);
    const channelName = jsonObject.common.channelName;
    if (channelName != null) {
      this.setChannelName(channelName);
    } else {
      this.setChannelName("");
    }
    const mailAddress = jsonObject.common.mailAddress;
    if (mailAddress != null) {
      this.setMailAddress(mailAddress);
    } else {
      this.setMailAddress("");
    }
  }
}

export const commonModule = getModule(Common);
