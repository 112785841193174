import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

interface ICallbackInterface {
  success: () => void;
  failure: (error: Error) => void;
}

export default class CreatorUserManager {
  // cognitoUserPool
  private get cognitoUserPool(): AmazonCognitoIdentityJs.CognitoUserPool {
    return new AmazonCognitoIdentityJs.CognitoUserPool({
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID as string,
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID as string,
    });
  }

  // cognitoUser
  private cognitoUser(username: string): AmazonCognitoIdentityJs.CognitoUser {
    return new AmazonCognitoIdentityJs.CognitoUser({
      Username: username,
      Pool: this.cognitoUserPool,
    });
  }

  // authenticationDetails
  private authenticationDetails(
    username: string,
    password: string
  ): AmazonCognitoIdentityJs.AuthenticationDetails {
    return new AmazonCognitoIdentityJs.AuthenticationDetails({
      Username: username,
      Password: password,
    });
  }

  private async getCurrentUser(): Promise<AmazonCognitoIdentityJs.CognitoUser> {
    const currentUser = await this.cognitoUserPool.getCurrentUser();
    if (currentUser == null) {
      throw new Error("getCurrentUser");
    }
    return currentUser;
  }

  private async getSession(): Promise<AmazonCognitoIdentityJs.CognitoUserSession> {
    // AmazonCognitoIdentityJs.CognitoUserSession

    let currentUser: AmazonCognitoIdentityJs.CognitoUser | null = null;
    let getCurrentUserTryCount = 0;
    do {
      getCurrentUserTryCount++;
      try {
        currentUser = await this.getCurrentUser();
      } catch (ex) {
        throw ex;
      }
      if (getCurrentUserTryCount >= 3) {
        throw new Error("getCurrentUserTryCount");
      }
    } while (currentUser == null);

    let session: null | AmazonCognitoIdentityJs.CognitoUserSession = null;
    let getSessionTryCount = 0;
    do {
      getSessionTryCount++;
      try {
        await currentUser.getSession(
          (
            error: Error | null,
            result: null | AmazonCognitoIdentityJs.CognitoUserSession
          ) => {
            session = result;
          }
        );
      } catch (ex) {
        console.log(ex);
      }
      if (getSessionTryCount >= 3) {
        throw new Error("getSessionTryCount");
      }
    } while (session == null);

    return session;
  }

  public async getJwtToken(): Promise<string> {
    const session = await this.getSession();

    let idToken: any = undefined;
    let getIdTokenTryCount = 0;
    do {
      getIdTokenTryCount++;
      try {
        idToken = await session.getIdToken();
      } catch (ex) {
        console.log(ex);
      }
      if (getIdTokenTryCount >= 3) {
        throw new Error("getJwtToken 1");
      }
    } while (idToken == undefined);

    let jwtToken: any = undefined;
    let getJwtTokenTryCount = 0;
    do {
      getJwtTokenTryCount++;
      try {
        jwtToken = await idToken.getJwtToken();
      } catch (ex) {
        console.log(ex);
      }
      if (getJwtTokenTryCount >= 3) {
        throw new Error("getJwtToken 2");
      }
    } while (jwtToken == undefined);

    return jwtToken;
  }

  public async sinInCheck(): Promise<void> {
    await this.getSession();
  }

  public async credential(): Promise<void> {
    const jwtToken = await this.getJwtToken();
    AWS.config.region = process.env.VUE_APP_AWS_REGION as string;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID as string,
      Logins: {
        [`cognito-idp.${
          process.env.VUE_APP_AWS_REGION as string
        }.amazonaws.com/${process.env.VUE_APP_COGNITO_USER_POOL_ID as string}`]:
          jwtToken,
      },
    });
    await AWS.config.credentials;
  }

  public signIn(
    params: {
      mailAddress: string;
      password: string;
    },
    callbacks: ICallbackInterface
  ): void {
    const cognitoUser = this.cognitoUser(params.mailAddress);
    const authenticationDetails = this.authenticationDetails(
      params.mailAddress,
      params.password
    );

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (
        cognitoUserSession: AmazonCognitoIdentityJs.CognitoUserSession
      ) => {
        callbacks.success();
      },
      onFailure: (err: Error) => {
        callbacks.failure(err);
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        callbacks.failure(new Error("newPasswordRequired"));
      },
      mfaRequired: (challengeName: any, challengeParameters: any) => {
        callbacks.failure(new Error("mfaRequired"));
      },
      customChallenge: (challengeParameters: any) => {
        callbacks.failure(new Error("customChallenge"));
      },
    });
  }

  public signUp(
    params: {
      mailAddress: string;
      password: string;
    },
    callbacks: ICallbackInterface
  ): void {
    this.cognitoUserPool.signUp(
      params.mailAddress,
      params.password,
      [
        new AmazonCognitoIdentityJs.CognitoUserAttribute({
          Name: "email",
          Value: params.mailAddress,
        }),
      ],
      [],
      (err: any, result: any) => {
        if (err == null) {
          callbacks.success();
        } else {
          callbacks.failure(err);
        }
      }
    );
  }

  public signUpConfirm(
    params: {
      mailAddress: string;
      code: string;
    },
    callbacks: ICallbackInterface
  ): void {
    this.cognitoUser(params.mailAddress).confirmRegistration(
      params.code,
      false,
      (err: any, result: any) => {
        if (err == null) {
          callbacks.success();
        } else {
          callbacks.failure(err);
        }
      }
    );
  }

  public signUpConfirmReSend(
    params: {
      mailAddress: string;
    },
    callbacks: ICallbackInterface
  ): void {
    this.cognitoUser(params.mailAddress).resendConfirmationCode(
      (err: any, result: any) => {
        if (err == null) {
          callbacks.success();
        } else {
          callbacks.failure(err);
        }
      }
    );
  }

  public async signOut(callbacks: ICallbackInterface): Promise<void> {
    try {
      const currentUser = await this.getCurrentUser();
      currentUser.signOut();
      callbacks.success();
    } catch (ex) {
      callbacks.failure(ex);
    }
  }

  public async signOutAll(callbacks: ICallbackInterface): Promise<void> {
    try {
      const currentUser = await this.getCurrentUser();
      const session = await this.getSession();
      currentUser.setSignInUserSession(session);
      await this.credential();
      await currentUser.globalSignOut({
        onSuccess: (msg: string) => {
          callbacks.success();
        },
        onFailure: (err: Error) => {
          callbacks.failure(err);
        },
      });
    } catch (ex) {
      callbacks.failure(ex);
    }
  }

  public async changePassword(
    params: {
      oldPassword: string;
      newPassword: string;
    },
    callbacks: ICallbackInterface
  ): Promise<void> {
    try {
      const currentUser = await this.getCurrentUser();
      const session = await this.getSession();
      currentUser.setSignInUserSession(session);
      await currentUser.changePassword(
        params.oldPassword,
        params.newPassword,
        (err: any, result: any) => {
          if (err !== null) {
            callbacks.failure(err);
          } else {
            callbacks.success();
          }
        }
      );
    } catch (ex) {
      callbacks.failure(ex);
    }
  }
}
