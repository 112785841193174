// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import Navigation from "@/components/Navigation/Navigation.vue";

@Component({
  components: {
    Navigation,
  },
})
export default class extends BaseVue {
}
