// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultHeader from "@/components/DefaultHeader/DefaultHeader.vue";
import DefaultFooter from "@/components/DefaultFooter/DefaultFooter.vue";
import SettingModal from "@/components/SettingModal/SettingModal.vue";
import LoadingModal from "@/components/LoadingModal/LoadingModal.vue";

@Component({
  components: {
    DefaultHeader,
    DefaultFooter,
    SettingModal,
    LoadingModal,
  },
})
export default class extends Vue {
  public async settingModalOpen(): Promise<void> {
    this.$modal.show("setting-modal");
  }
}
